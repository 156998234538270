import React, { useEffect, useState } from "react";
import { Container, ListGroup, Button } from "react-bootstrap";
import app from "./base";
import Navig from "./components/Nav"
import { FaFilePdf, FaVideo } from "react-icons/fa";
// import { FaBeer } from 'react-icons/fa';

const Home = () => {
  const userId = app.auth().currentUser.uid
    const [ courses, setCourses ] = useState([])
    const [video, setVideo] = useState("")
    useEffect(() => {
        async function fetchData() {
          let coursesRaw = await app.firestore().collection('courses').where('users', 'array-contains', userId).get()
          coursesRaw = coursesRaw.docs.map((item) => {
            return item.data()
          })
          setCourses(coursesRaw)
        }
        fetchData();
      }, [userId]); 


  const renderLesson = (item, key) => {
    const f = item.files.map((i) => {
      const type = i.indexOf("youtu") > -1 ? "video" : "pdf"
      return {
        "type": type,
        "file": i
      } 
    })
    
    return (
      <ListGroup.Item key={key} as="li" className="" variant="success">
        <div className="fw-bold">&nbsp;{item.name}</div>
        <br />
        {f.length ? (
          <ListGroup>
            {f.map((item, key) => <ListGroup.Item key={key}>
              {item.type === "pdf" ? (
                // eslint-disable-next-line react/jsx-no-target-blank
                <a href={item.file} target="_blank" title="Scarica" className="btn btn-warning btn-sm">
                  <FaFilePdf />
                  &nbsp;
                  Dispensa
                </a>
              ) : (
                // eslint-disable-next-line react/jsx-no-target-blank
                <a href={item.file} target="_blank" title="Apri" className="btn btn-warning btn-sm">
                  <FaVideo />
                  &nbsp;
                  Video
                </a>
              )}
            
          </ListGroup.Item>)}
          </ListGroup>
        ) : (
          <span>
            No files yet available
          </span>
        )}
      </ListGroup.Item>
    )
  }

  const renderCourse = (item, key) => {
    return (
      <ListGroup.Item as="li" key={key}  className="">
        <h2>Corso: {item.name}</h2>
        <br />
        <ListGroup as="ol">
          {item.lessons.length && item.lessons.map((item, key) => renderLesson(item, key))}
        </ListGroup>
      </ListGroup.Item>
    )
  }

  const renderCourses = (courses) => {
    return (
      <ListGroup as="ol">
        {courses && courses.map(renderCourse)}
      </ListGroup>
    )
  }
      
  return (
    <>
      <Navig />
      <br /><br /><br />
      <Container>
        {renderCourses(courses)}
      </Container>
      {video && (
        <div className="full">
          <br />
          <p>
           <Button variant="danger" onClick={() => { setVideo(false); return false;}}>Chiude</Button>
          </p>
          <div style={{width: "90%", height: "90vh", textAlign: 'center', margin: '0 auto', backgroundColor: 'white'}}>
            <iframe src={video} width="90%" height="90%" title={`Video - ${video}`}></iframe>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;

                // <Link onClick={() => { setVideo(item.file); return false; }}>
                //   {renderFileButton(item)}
                // </Link>