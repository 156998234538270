import React, { useEffect, useState } from "react"
import { Navbar, Container } from "react-bootstrap"
import app from "../base";

const Navig = () => {
    const [ fullName, setFullName ] = useState("")
    const userId = app.auth().currentUser.uid
    
    useEffect(() => {
        async function fetchData() {
          let userel = await app.firestore().collection('users').doc(userId).get()
          if(userel.exists) {
            setFullName(userel.data().fullname)
          }
        }
        fetchData();
      }, [userId]); 

    return (
        <Navbar bg="dark" variant="dark">
            <Container>
                <Navbar.Brand href="#home">AFEP.school</Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                <Navbar.Text>
                    Registrato come: &nbsp;
                    <a href="#home" title="Disconnessione" onClick={() => { app.auth().signOut(); document.location="/login"}}>{fullName}</a>
                </Navbar.Text>
                </Navbar.Collapse>
            </Container>
            </Navbar>
    )
}

export default Navig